"use client"

import Link from "next/link"
import { useRouter } from "next/navigation"

export default function DefaultNavbarLink ({linkData, setLoginModalOpenState}) {
  const router = useRouter();

  return (
    <Link
      onClick={() => {
        /* const userSession = undefined

        if(userSession) {
          //push to results page
          router.push()
        } */

        if(linkData.label === "Descargar Resultados") {
          //code for when implementing login modals
          /* setLoginModalOpenState(true) */

          //this redirects to the existing login website of mascolab
          router.push("https://mascolab.com/software/?name=d56b699830e77ba53855679cb1d252da")
        }
      }}
      href={linkData.link ? linkData.link : "/"} className="h-full lg:px-[15px] xl:px-[20px] 2xl:px-[35px] nav-link__cont"
    >
      <div className="nav-link relative h-full text-lightGray">
        <div className="nav-link-inner-cont w-full h-full flex items-center ">
          <p>{linkData.label}</p>
        </div>
      </div>
    </Link>
  )
}
export default function WhiteOutlinedButton ({ children, className, onClickFunc, isNavbarRegisterButton=false }) {
  const buttonContStyles = isNavbarRegisterButton ?
    `my-2 transition-all duration-300 w-full max-w-[100px] rounded-[25px] mx-1 xl:my-0 ${className}`
      :
    `my-2 transition-all duration-300 w-full border-[2px] rounded-[25px] mx-1 xl:my-0 ${className}`

  const buttonStyles = isNavbarRegisterButton ?
    `w-full h-full`
      :
    `w-full h-full px-2 2xl:px-6`

    

  return (
    <div
      className={buttonContStyles}
      onClick={() => { onClickFunc() }}
    >
      <button className={buttonStyles}>
        {children}
      </button>
    </div>
  )
}
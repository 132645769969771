import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import WhiteOutlinedButton from "../buttons/WhiteOutlinedButton";
import GreenOutlinedButton from "../buttons/GreenOutlinedButton";
import { navbarLinks } from "@/utils/hardcodedData"

export default function RegisterButtonsSection({ /* setLoginModalOpenState, */ router, toggleMenuDrawer, openMenuDrawer }) {
  return (
      <>
        <div className="hidden relative right-0 z-30 bg-black w-[56px] register-nav-section__cont h-full text-white  items-center justify-center text-center lg:flex xl:text-[14px] xl:w-[215px] 2xl:w-[325px]">

          <div className="absolute bg-black w-[161px] h-full z-10 flex justify-center
              xl:hidden"
          >
            <div className="register-nav-icon relative z-20 w-full h-full flex justify-center items-center transition duration-200 hover:bg-lowOpacitytWhite xl:hidden">
              <AccountCircleOutlinedIcon sx={{width: "30px", height: "30px"}}/>
            </div>
          </div>

          {/* fix error where the buttons cont appear above the cont of the user icon*/}
          <div
            className="log-and-sign-buttons__drawer z-0 py-2 flex flex-col items-center justify-center absolute w-[161px] bg-black border border-lowOpacityWhite rounded-bl-sm
            lg:bottom-0 lg:px-2
            xl:border-[0px] xl:static xl:h-[64px] xl:min-w-[288px] xl:flex xl:flex-row xl:justify-end xl:px-0
            2xl:w-[325px]"
          >

            <WhiteOutlinedButton
              className="border-0 px-0 w-full"
              isNavbarRegisterButton={true}
              onClickFunc={() => {
                //this line is for in proccess mascolab website
                /* setLoginModalOpenState(true) */

                //this redirects to the existing login website of mascolab
                router.push("https://mascolab.com/software/?name=d56b699830e77ba53855679cb1d252da")
              }}
            >
              Iniciar Sesion
            </WhiteOutlinedButton>

            <GreenOutlinedButton
              className="text-white mx-0 py-[2px] xl:max-w-[128px] xl:w-[128px] xl:py-2 xl:px-2 2xl:max-w-[120px]"
              onClickFunc={() => {
                //this redirects to the existing create account website of mascolab
                router.push("https://mascolab.com/recetario/registro.php")
              }}
            >
              Registrarse
            </GreenOutlinedButton>

          </div>
        </div>
    </>
  )
}
"use client"

import Link from "next/link"
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useState } from "react";
import WhiteOutlinedButton from "../buttons/WhiteOutlinedButton";
import GreenOutlinedButton from "../buttons/GreenOutlinedButton";
import { useRouter } from "next/navigation";

function DefaultLink ({linkData}) {
  return (
    <li className="mobile-default-link__cont px-10 relative duration-100 active:bg-lowOpacityGreen">
      <Link
        className="list-disc"
        onClick={() => {
          /* const userSession = undefined

          if(userSession) {
            //push to results page
            router.push()
          } */

          if(linkData.label === "Descargar Resultados") {
            setLoginModalOpenState(true)
          }
        }}
        href={linkData.link}
      >
        <p className="underline py-4 relative">{linkData.label}</p>
      </Link>
    </li>
  )
}

function NestedLink ({linkData, updateNestedLinkOpen, currentNestedLinkOpen}) {
  const lowercaseLinkLabel = linkData.label.toLowerCase()
  const isThisNestedLinkOpen = currentNestedLinkOpen === lowercaseLinkLabel ? true : false

  function downloadICARegister () {
    const fileName = "Registro-ICA.pdf"
    const urlOrigin = window.location.origin

    const aTag = document.createElement("a")
    aTag.href = `${urlOrigin}/${fileName}`
    aTag.setAttribute("download", fileName)
    document.body.appendChild(aTag)

    console.log({aTag, fileName, urlOrigin})

    aTag.click()
    aTag.remove()
  }

  return (
    <li
      className="mobile-nested-link__cont px-10 duration-200"
    >
      <div className="list-disc w-full h-full">
        <div
          className="mobile-nested-link__label-cont w-full flex items-center justify-start hover:cursor-pointer"
          onClick={() => {
            if(isThisNestedLinkOpen) {
              updateNestedLinkOpen("")
            } else {
              updateNestedLinkOpen(lowercaseLinkLabel)
            }
          }}
        >
          <p className="mobile-nested-link__label py-4 transition duration-100 underline mb-2 ">{linkData.label}</p>
          <ArrowForwardIosIcon className={`arrow-icon transition duration-100 ${isThisNestedLinkOpen ? "rotate-90": ""}`} sx={{
              width: "12px",
              height: "12px",
              margin: "0 0 6px 6px",
            }}
          />
        </div>
        <ul className={`mobile-nested-link-list list-disc transition duration-300 ${isThisNestedLinkOpen ? "" : "hidden"}`}>
          {
            linkData.services.map((service, index) => (
              <div key={index} className="my-2" >

                <p className="pl-4 py-1 mb-2" >{service.label}:</p>

                <ul className="w-full">

                  {service.dataGroup.map((option, index) => (

                    <li key={index} className="w-full ml-10 hover:underline max-w-[240px] mobile-nested-link__son duration-200 relative">

                      <Link
                        onClick={() => {
                          if(option.label === "Registros ICA") {
                            downloadICARegister()
                          }
                        }}
                        href={option.link} key={index} className="flex items-center active:text-green">
      
                        <p className="mobile-link py-2 pl-2 relative duration-100">{option.label}</p>
      
                      </Link>

                    </li>

                  ))}

                </ul>

              </div>
            ))
          }
        </ul>
      </div>
    </li>
  )
}



export default function MobileNavbarLinksList ({data, /* setLoginModalOpenState */}) {
  const router = useRouter()
  const [currentNestedLinkOpen, setCurrentNestedLinkOpen] = useState("")

  //the argument nestedLinkOpen can be either "servicios" or "soporte"
  function updateNestedLinkOpen (newNestedLink) {
    setCurrentNestedLinkOpen(newNestedLink)
  }

  return (
    <div className="w-full">
      <ul className="w-full">
        {
          data.map((link, index) => (
            (
              link.services ? 
                <NestedLink linkData={link} key={index} updateNestedLinkOpen={updateNestedLinkOpen} currentNestedLinkOpen={currentNestedLinkOpen} />
                :
                <DefaultLink linkData={link} key={index} />
            )
          ))
        }
      </ul>
      <div className="px-10 mb-10 mt-4 flex flex-col items-center">
        <WhiteOutlinedButton
        className={"py-2 hover:bg-white hover:text-black hover:border-white"}
          onClickFunc={() => {
            //code neccessary only when using login modal
            /* setLoginModalOpenState(true) */

            //this redirects to the existing login website of mascolab
            router.push("https://mascolab.com/software/?name=d56b699830e77ba53855679cb1d252da")
          }}
        >
          Iniciar Sesion
        </WhiteOutlinedButton>
        <GreenOutlinedButton
          className={"py-2 hover:text-white"}
          onClickFunc={() => {
            router.push("https://mascolab.com/recetario/registro.php")
          }}
        >
          Registrarse
        </GreenOutlinedButton>
      </div>
    </div>
  )
}
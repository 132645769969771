export default function GreenOutlinedButton ({ children, className, onClickFunc }) {
  return (
    <div
    onClick={() => {
      onClickFunc()
    }}
      className={`my-2 transition-all w-full text-green duration-300 rounded-[25px] border-[2px] border-green hover:bg-green
      mx-1 xl:my-0 ${className}`}
    >
      <button className="w-full h-full px-2 text-center">
        {children}
      </button>
    </div>
  )
}
export const navbarLinks = [
  {
    label: "Preguntas Frecuentes",
    link: "#FAQ"
  },
  {
    label: "Bandeja de Resultados",
    link: "https://mascolab.com/software/"
  },
  {
    label: "Escuela HDX",
    link: "https://mascolab.com/escuelahdx/"
  },
  {
    label: "Servicios",
    services: [
      {
        label: "Conoce nuestros servicios",
        dataGroup: [
          {
            label: "PCR tiempo real sonda de hidrolisis",
            link: "/"
          },
          {
            label: "PCR sonda hidrolisis",
            link: "/"
          },
          {
            label: "Exámenes de rutina",
            link: "/"
          },
          {
            label: "Títulos de rabia",
            link: "/"
          },
          {
            label: "Sexaje en aves",
            link: "/"
          },
          {
            label: "Pruebas genéticas",
            link: "/"
          },
        ]
      },
      {
        label: "Otros servicios",
        dataGroup: [
          {
            label: "Insumos para envío de muestras",
            link: "/"
          },
          {
            label: "Confirmación de resultado PCR",
            link: "/"
          },
          {
            label: "Boletín epidemiológico",
            link: "https://mascolab.com/boletin/"
          },
          {
            label: "Venta de reactivos",
            link: "https://www.mascolab.com/protocolizate/"
          },
        ]
      }
    ]
  },
  {
    label: "Soporte",
    services: [
      {
        label: "Conoce los servicios de soporte",
        dataGroup: [
          {
            label: "Contáctanos",
            link: "#footer"
          },
          {
            label: "PQR",
            link: "https://mascolab.com/pqrsa/index.php"
          },
          {
            label: "Analistas",
            link: "https://mascolab.com/laboratorio/Views/login.php"
          }
        ]
      },
      {
        label: "Certificaciones/Acreditaciones",
        dataGroup: [
          {
            label: "Registro ONAC",
            link: "/"
          },
          {
            label: "ISO/IEC 17025",
            link: "https://mascolab.com/norma17025/"
          },
          {
            label: "Registro ICA",
            link: "/"
          }
        ]
      }
    ]
  },
]

export const statisticsData = {
  statisticOne: {
    label: "EXAMENES REPORTADOS",
    value: "90,850"
  },
  statisticTwo: {
    label: "ORDENES RECIBIDAS",
    value: "90,888"
  },
  statisticThree: {
    label: "CLIENTES ACTIVOS",
    value: "450"
  }
}

export const FAQSectionData = {
  title: "Preguntas Frecuentes",
  questionsAndAnswers: [
    {
      id: 1,
      question: "¿Cómo programo una recolección para mis muestras?",
      answer: `Primero debes registrarte en nuestra página web, luego dirigirte a nuestro recetario en linea, rellenar los datos del paciente, el tipo de examen que deseas realizar, la hora y dirección de recogida. Una vez envíes el recetario, inmediatamente se programa tú recolección según la hora que hayas seleccionado, el domicilio es totalmente gratis, rápido y eficaz.`
    },
    {
      id: 2,
      question: "¿En qué ciudades de Colombia hacen recolección?",
      answer: "Tenemos cobertura en todo el país, lo único que tienes que hacer es programar tu recolección en nuestra página web."
    },
    {
      id: 3,
      question: "¿Cuanto tiempo se demora en obtener los resultados?",
      answer: "Si te encuentras ubicado/a en Bogotá o Medellín el tiempo es de 2 días hábiles, si por el contrario estás ubicado/a en otra ciudad, el tiempo es de 3-4 días hábiles. Siempre podrás chequear el estado del examen desde tú bandeja de entrada."
    },
    {
      id: 4,
      question: "¿Cómo sé si mi resultado ya está listo?",
      answer: "Cuando tú resultado esté listo, te notificaremos enviando un mensaje vía correo electrónico y SMS a la dirección de correo y número telefónico que hayas ingresado en nuestro registro."
    },
    {
      id: 5,
      question: "¿En que formato recibo los resultados y dónde los veo?",
      answer: "Después de registrarte tendrás acceso a tu bandeja de entrada, ahí puedes visualizar de manera online todos los resultados que hayas recibido y también descargarlos en formato PDF para su impresión."
    },
    {
      id: 6,
      question: "¿Cómo se realiza el pago y la facturación?",
      answer: "Una vez realices la orden, automáticamente se emitirá una factura electrónica que se enviará al correo que hayas usado en nuestro registro, esa misma factura contendrá un enlace con diferentes métodos de pago como; PSE, transferencia bancaria, tarjeta de crédito, efecty, etc."
    },
    {
      id: 7,
      question: "¿Qué pasa si cancelo mi examen?",
      answer: "Si en el momento de la cancelación la muestra aún no ha sido recogida, no tendrá ningún costo por cancelación, de lo contrario si ya fue recolectada, se le cobrará el valor del domicilio y si la muestra ya está en proceso, se le sumara el valor de la extracción del ADN."
    },
  ]
}

export const mxFooterData = {
  companyName: "Mascolab®",
  locations: [
    {
      city: "Ciudad de México",
      address: "Perpetua No. 40 Col. San José Insurgentes Alcaldia Benito Juarez C.P.03900 entre Insurgentes y damas",
      url: "https://www.google.com/maps/place/Cda.+Perpetua+40,+San+Jos%C3%A9+Insurgentes,+Benito+Ju%C3%A1rez,+03900+Ciudad+de+M%C3%A9xico,+CDMX,+Mexico/@19.3927818,-99.2135251,11.59z/data=!4m10!1m2!2m1!1sPerpetua+No.+40+Col.+San+Jos%C3%A9+Insurgentes+Alcaldia+Benito+Juarez+C.P.03900!3m6!1s0x85d1ff9a72276423:0x43cda798e26920c2!8m2!3d19.3661995!4d-99.1830214!15sCktQZXJwZXR1YSBOby4gNDAgQ29sLiBTYW4gSm9zw6kgSW5zdXJnZW50ZXMgQWxjYWxkaWEgQmVuaXRvIEp1YXJleiBDLlAuMDM5MDCSARBnZW9jb2RlZF9hZGRyZXNz4AEA!16s%2Fg%2F11vjphtpx1?entry=ttu&g_ep=EgoyMDI0MTAwNS4yIKXMDSoASAFQAw%3D%3D"
    }
  ],
  contactUs: {
    title: "Contáctanos",
    contacts: [
      {
        label: "Teléfono",
        value: "(+52) 55 9016 1649 o (+52) 56 3059 0392"
      },
      {
        label: "Email",
        value: "laboratorio@mascolab.com"
      }
    ]
  }
}

export const coFooterData = {
  companyName: "Mascolab®",
  locations: [
    {
      city: "Bogotá",
      url: "https://www.google.com/maps/place/MASCOLAB%C2%AE/@4.7062925,-74.0510442,18z/data=!4m6!3m5!1s0x8e3f8545c636f5f9:0xd41df800e4b28cf4!8m2!3d4.7064441!4d-74.051107!16s%2Fg%2F11f53zvg71?hl=es&entry=ttu&g_ep=EgoyMDI0MDkxNS4wIKXMDSoASAFQAw%3D%3D",
      workingTimes: [
        {
          days: "Lunes a Viernes",
          time: "9 a.m. - 6 p.m."
        },
        {
          days: "Sábados",
          time: "9 a.m. - 3 p.m."
        },
      ],
    },
    {
      city: "Envigado",
      url: "https://www.google.com/maps/place/Mascolab/@6.1658331,-75.6182665,13.28z/data=!4m6!3m5!1s0x8e44282b3c1ceda5:0x7053f989c42841cf!8m2!3d6.163141!4d-75.601862!16s%2Fg%2F11fzf187vp?hl=es&entry=ttu&g_ep=EgoyMDI0MDkxNS4wIKXMDSoASAFQAw%3D%3D",
      workingTimes: [
        {
          days: "Lunes a Viernes:",
          time: "9 a.m. - 6 p.m."
        },
        {
          days: "Sábados",
          time: "9 a.m. - 3 p.m."
        },
      ],
    }
  ],
  contactUs: {
    title: "Contáctanos",
    contacts: [
      {
        label: "E-mail",
        value: "laboratoriomascolab@gmail.com"
      },
      {
        label: "WhatsApp",
        value: " +57 3012723806"
      },
      {
        label: "Teléfono",
        value: "(601) 457 0052"
      },
    ]
  },

  copyRightBoldText: "Copyright © 2012 - 2024",
  copyRightNormalText: "Todos los Derechos Reservados Mascolab S.A.S"
}

export const loginModalData = {
  title: "Bienvenido de nuevo",
  subtitle: "Inicia sesión con tu cuenta",
  appleLoginButtonText: "Continuar con Apple",
  googleLoginButtonText: "Continuar con Google",
  stayConnectedText: "Permanecer conectado",
  forgotPasswordText: "¿Has olvidado tu contraseña?",
  dontHaveAccountText: {
    normalPartText: "¿No tienes una cuenta?",
    coloredPartText: "Regístrate"
  }
} 
"use client"

import "./navbar.css";

import { navbarLinks } from "@/utils/hardcodedData"
import NavbarLinkWithDrawer from "./navbarLinkWithDrawer"
import DefaultNavbarLink from "./DefaultNavbarLink"
import { useState } from "react";
import desktopLogo from "../../../public/logos/mascolab/logo-desktop.svg"
import mobileLogo from "../../../public/logos/mascolab/logo-mobile.svg"
import Image from "next/image";
import Drawer from '@mui/material/Drawer';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import MobileNavbarLinksList from "./MobileNavbarLinksList";

import { useRouter } from "next/navigation";
import RegisterButtonsSection from "./RegisterButtonsSection";

export default function Navbar ({ setLoginModalOpenState }) {
  const [openMenuDrawer, setOpenMenuDrawer] = useState(false)
  const router = useRouter()

  const defaultNavbarLinks = navbarLinks.filter(navLink => navLink.services === undefined)
  const withDrawerNavbarLinks = navbarLinks.filter(navLink => navLink.services !== undefined)

  function toggleMenuDrawer (newState) {
    setOpenMenuDrawer(newState)
  }

  return (
    <nav className="fixed top-0 w-full z-40 h-[65px] bg-black border-b-[1px] border-lowOpacityWhite">
      <div className="z-20 w-full max-w-[1568px] mx-auto h-[64px] relative flex justify-between items-center px-4 md:px-10 lg:px-16 xl:px-24 2xl:w-[90%] 2xl:px-0

      ">
        <div className="w-[90%] max-w-[900px] h-full items-center flex justify-start
        xl:w-[80%] xl:max-w-[900px]
        2xl:max-w-[1080px]"
        >
          <div className="flex items-center lg:mr-[15px] xl:mr-[20px] 2xl:mr-[35px] justify-center text-center text-white">

            <Image src={mobileLogo} width={30} height={20} className="sm:w-[40px] sm:hidden" alt="Mascolab Logo" />

            <Image src={desktopLogo} width={100} height={40} className="hidden sm:block lg:w-[110px] xl:w-[135px] 2xl:w-[170px]" alt="Mascolab Logo" />

          </div>

          <div className="h-full text-lightGray text-[14px] text-center hidden lg:flex xl:w-[%] 2xl:text-[15px]">
            {
              defaultNavbarLinks.map((navLink, index) => {
                return (
                  <DefaultNavbarLink linkData={navLink} key={index} />
                )
              })
            }
            <div className="drawer-links-cont w-[206px] flex">
              {
                withDrawerNavbarLinks.map((navLink, index) => {
                  return (
                    <NavbarLinkWithDrawer linkData={navLink} key={index} />
                  )
                })
              }
            </div>
            <div className='drawer-bg w-[5000px] absolute h-[330px] hidden opacity-0 -left-[1000px] -bottom-[331px] bg-black transition'></div>
          </div>
        </div>

        <RegisterButtonsSection openMenuDrawer={openMenuDrawer} toggleMenuDrawer={toggleMenuDrawer} router={router} />

        <div className="lg:hidden">
          <div
            className="p-2 hover:bg-lowOpacityWhite rounded-xl transition duration-300"
            onClick={() => {toggleMenuDrawer(true)}}
          >
            <MenuIcon sx={{fill: "white"}}/>
          </div>
          <Drawer
            className="max-h-[100vh]"
            anchor="right"
            open={openMenuDrawer}
            onClose={() => {toggleMenuDrawer(false)}}
          >
            <div className="w-full h-full bg-black pt-20 relative border-l-[1px] border-lowOpacityWhite">
              <div
                className="absolute top-8 left-10 p-2 w-[40px] flex items-center justify-center hover:bg-lowOpacityWhite rounded-[25px] transition duration-300"
                onClick={() => {toggleMenuDrawer(false)}}
              >
                <CloseIcon sx={{fill: "white"}}/>
              </div>
              <div className="w-[330px] max-h-[90vh] overflow-y-scroll bg-black text-white">
                <MobileNavbarLinksList data={navbarLinks} /* setLoginModalOpenState={setLoginModalOpenState} */ />
              </div>
            </div>
          </Drawer>
        </div>

      </div>
    </nav>
  )
}